import React from 'react'
import { graphql } from 'gatsby'
import { injectIntl } from 'gatsby-plugin-react-intl'
import LayoutLight from '@components/layouts/light/LayoutLight'
import { color } from '@configs/utilities'
import RatingSection from '@sections/home/RatingSection'
import BenefitsSection from '@sections/home/BenefitsSection'
import AdvertisementSection from '@sections/common/AdvertisementSection'
import TestimonialsSection from '@sections/common/TestimonialsSection'
import FeaturesSection from '@sections/home/FeaturesSection'
import LandingHeroSection from '@sections/landings/LandingHeroSection'
import TemplatesSection from '@sections/home/TemplatesSection'
import ReadySection from '@sections/common/ReadySection'
import AiAssistanceSection from '@sections/home/AIAssistanceSection'
import { generateAppLink } from '@configs/helpers'
import TrustedBySection from '@sections/home/TrustedBySection'
export const query = graphql`
  query ($slug: String!) {
    landingsJson(slug: { eq: $slug }) {
      slug
      openModal
      trigger
      title
      text
      background {
        childImageSharp {
          original {
            src
          }
        }
      }
      illustration {
        childImageSharp {
          original {
            src
          }
        }
      }
      button {
        goToApp
        path
        text
      }
      readySection {
        title
      }
      rankedTag
      trustedBySection
    }
  }
`

const Landings = ({ data, intl }) => {
  const landing = data.landingsJson

  const backgroundImage = data.landingsJson.background.childImageSharp.original.src
  const illustrationImage = data.landingsJson.illustration.childImageSharp.original.src

  return (
    <LayoutLight pageName={`landing-${landing.slug}`}>
      <LandingHeroSection
        backgroundImage={backgroundImage}
        illustration={illustrationImage}
        intl={intl}
        landing={landing}
        // external={landing.button.goToApp}
        // href={generateAppLink(landing.button.path, intl.locale)}
        to={landing.button.path}
        external
        href={generateAppLink('signup', intl.locale)}
        buttonText={intl.formatMessage({ id: landing.button.text })}
        explainText={landing.button.goToApp ? intl.formatMessage({ id: 'home.hero.try' }) : ''}
        alt={landing.slug}
        rankedTag={landing.rankedTag}
      />
      <RatingSection />
      {landing.trustedBySection && <TrustedBySection />}
      <FeaturesSection open={landing.trigger} />
      <AiAssistanceSection />
      <BenefitsSection />
      <AdvertisementSection
        componentBackground={color.blueDark}
        titleText={intl.formatMessage({ id: landing.readySection.title })}
        subtitleText={intl.formatMessage({ id: 'home.businessPlanning.subtitle' })}
        // external={landing.button.goToApp}
        // href={generateAppLink('signup', intl.locale)}
        to={landing.button.path}
        external
        href={generateAppLink('signup', intl.locale)}
        buttonBackground={color.yellow}
        buttonColor={color.white}
        buttonText={intl.formatMessage({ id: landing.button.text })}
      />
      <TemplatesSection />
      <TestimonialsSection />
      <ReadySection
        sectionBackground={color.blueLight}
        title={intl.formatMessage({ id: 'home.readyToStartYourAdventure.title' })}
        description={intl.formatMessage({ id: 'home.readyToStartYourAdventure.description' })}
        // external={landing.button.goToApp}
        // href={generateAppLink('signup', intl.locale)}
        to={landing.button.path}
        external
        href={generateAppLink('signup', intl.locale)}
        buttonText={intl.formatMessage({ id: landing.button.text })}
        explain
        explainText={
          landing.button.goToApp ? intl.formatMessage({ id: 'home.readyToStartYourAdventure.explainText' }) : ''
        }
      />
    </LayoutLight>
  )
}

export default injectIntl(Landings)
