import React from 'react'
import styled from 'styled-components'
import { color, breakpoint } from '@configs/utilities'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'

const TestimonialsCard = (props) => {
  return (
    <Wrapper>
      <CardTop>
        <StaticImage
          src="../../../../assets/images/home/testimonialsCards/stars.svg"
          alt="stars"
          width={120}
          height={35}
        />
        <StaticImage
          src="../../../../assets/images/home/testimonialsCards/capterra.png"
          alt="capterra"
          width={120}
          height={35}
        />
      </CardTop>
      <CardMain>
        <p className="cardHead">{props.intl.formatMessage({ id: props.item.node.head })}</p>
        <p className="cardText">{props.intl.formatMessage({ id: props.item.node.text })}</p>
        <a href="https://www.capterra.com/p/204342/IdeaBuddy/reviews/" target="_blank" rel="noreferrer">
          {props.intl.formatMessage({ id: 'home.testimonials.cards.more' })}
        </a>
      </CardMain>
      <CardBottom>
        <GatsbyImage
          image={props.item.node.personImage.childImageSharp.gatsbyImageData}
          alt={props.item.node.name}
          width={52}
          height={52}
        />
        <div className="testimonialsCardBottomText">
          <span className="testimonialsCardName">{props.item.node.name}</span>
          <span className="testimonialsCardJob">{props.intl.formatMessage({ id: props.item.node.job })}</span>
        </div>
      </CardBottom>
    </Wrapper>
  )
}

export default TestimonialsCard

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 450px;
  background: ${color.white};
  border-radius: 8px;
  border: 1px solid rgba(187, 201, 228, 0.33);
  padding: 30px;

  :last-child {
    display: none;
  }

  :nth-child(2) {
    display: none;
  }

  @media screen and (min-width: ${breakpoint.sm}) {
    width: 70%;
  }

  @media screen and (min-width: ${breakpoint.md}) {
    width: 45%;

    :nth-child(2) {
      display: flex;
    }
  }

  @media screen and (min-width: ${breakpoint.xl}) {
    width: 30%;
    max-width: 340px;

    :last-child {
      display: flex;
    }
  }
`

const CardTop = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const CardMain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
  color: ${color.blueDark};
  text-align: left;

  @media screen and (min-width: ${breakpoint.xxl}) {
    font-size: 16px;
  }

  .cardHead {
    font-weight: 700;
    margin-top: 20px;
  }

  .cardText {
    width: 95%;
    font-weight: 300;
    margin: 20px 0;
  }

  a {
    font-weight: 700;
    color: ${color.blue};
    transition: all 0.35s linear;
    @media (hover: hover) {
      &:hover {
        opacity: 0.65;
      }
    }
  }
`

const CardBottom = styled.div`
  display: flex;
  margin-top: 30px;
  width: 100%;

  .testimonialsCardBottomText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    color: ${color.font};
    margin-left: 15px;

    .testimonialsCardName {
      font-weight: 700;
      font-size: 16px;
    }

    .testimonialsCardJob {
      font-weight: 300;
      font-size: 14px;
    }
  }
`
