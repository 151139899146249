import React from 'react'
import { injectIntl } from 'gatsby-plugin-react-intl'
import styled from 'styled-components'
import { color, opacity, transition } from '@configs/utilities'
import arrow from '@assets/images/features/customizableTemplates/arrow.svg'
import { generateAppLink } from '@configs/helpers'

const UseTemplateLink = ({ intl, ...props }) => {
  return (
    <Wrapper href={generateAppLink('signup', intl.locale)} rel="noopener noreferrer">
      {intl.formatMessage({ id: 'home.templatesSlider.useTemplate' })} <img src={arrow} alt="arrow" />
    </Wrapper>
    // <Wrapper href={generateAppLink('signup', intl.locale, `template=${props.templateId}`)} rel="noopener noreferrer">
    //   {intl.formatMessage({ id: 'home.templatesSlider.useTemplate' })} <img src={arrow} alt="arrow" />
    // </Wrapper>
  )
}

export default injectIntl(UseTemplateLink)

const Wrapper = styled.a`
  display: flex;
  font-size: 14px;
  font-weight: 600;
  color: ${color.blue};
  transition: ${transition.default};
  margin-top: 10px;

  @media (hover: hover) {
    &:hover {
      opacity: ${opacity.default};
      img {
        margin-left: 10px;
        transition: ${transition.default};
      }
    }
  }

  img {
    margin-left: 5px;
  }
`
