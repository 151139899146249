import React from 'react'
import { injectIntl } from 'gatsby-plugin-react-intl'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { color, breakpoint } from '@configs/utilities'
import TestimonialsCard from '@components/pages/home/testimonialsCard/TestimonialsCard'
import SectionWrapper from '@components/ui/wrappers/SectionWrapper'
import SectionHeader from '@components/ui/typography/SectionHeader'

const TestimonialsSection = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query {
      allTestimonialsCardsJson {
        edges {
          node {
            head
            text
            personImage {
              childImageSharp {
                gatsbyImageData(width: 52, placeholder: NONE, formats: [AUTO, WEBP], breakpoints: [320, 768, 1380])
              }
            }
            name
            job
          }
        }
      }
    }
  `)

  return (
    <SectionWrapper sectionBackground={color.blueLight} showWaves wavesPosition="top">
      <SectionHeader
        handwriting={intl.formatMessage({ id: 'home.testimonials.usersSay' })}
        title={intl.formatMessage({ id: 'home.testimonials.goodHands' })}
      />
      <CardsWrapper>
        {data.allTestimonialsCardsJson.edges.map((item, index) => {
          return <TestimonialsCard key={index} intl={intl} item={item} />
        })}
      </CardsWrapper>
    </SectionWrapper>
  )
}

export default injectIntl(TestimonialsSection)

const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: ${breakpoint.sm}) {
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }

  @media screen and (min-width: ${breakpoint.md}) {
    align-items: stretch;
    justify-content: space-around;
  }
`
